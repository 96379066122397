import './FilterChip.scss';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, TextField, List, ListItemControl, Checkbox, Button } from 'react-md';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { FUSE_OPTIONS, idFormatter, truncate, orderByKey } from '../../utils';

const FilterChip = ({ onApply, onRemove, filterKey, label, direction, labelKey, data }) => {
  const [expanded, setExpanded] = useState(false);
  const [initialData, setInitialData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  const element = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    // shameful hack - style container to prevent break in flow
    const containerElement = element.current.parentElement;

    setTimeout(() => {
      const width = containerElement.clientWidth;
      containerElement.style.minWidth = width + 0 + 'px';
    }, 2000);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  function handleClick(e) {
    if (element.current.contains(e.target)) {
      return;
    }

    if (e.target.id === 'clear-filter-btn') {
      selectAll();
    }

    setExpanded(false);
    setSearchTerm('');
  }

  function countChecked() {
    const checkedOptions = _.filter(initialData, { isChecked: true }).length;

    if (checkedOptions === initialData.length) {
      return 'all';
    } else {
      return checkedOptions;
    }
  }

  function selectAll() {
    setInitialData(initialData.map(({ name, id }) => ({ name, id, isChecked: true })));
  }

  function deselectAll() {
    setInitialData(initialData.map(({ name, id }) => ({ name, id, isChecked: false })));
  }

  function isRemovable() {
    return _.filter(initialData, { isChecked: true }).length < initialData.length;
  }

  function handleSetExpand(value, e) {
    e.stopPropagation();
    setExpanded(value);
  }

  function handleApply(value, e) {
    e.stopPropagation();
    setExpanded(value);

    const selected = _.map(_.filter(initialData, { isChecked: true }), (item) => item.id);
    let applied = selected.length ? selected : null;
    onApply(applied, filterKey);
  }

  function handleRemove(e) {
    e.stopPropagation();
    setInitialData(initialData.map(({ name, id }) => ({ name, id, isChecked: true })));

    const selected = _.map(initialData, (item) => item.id);
    onApply(selected, filterKey);

    if (onRemove) {
      onRemove(null, filterKey);
    }
  }

  function handleOnChangeCheckbox(v, item) {
    const index = _.findIndex(initialData, obj => obj.id === item.id);
    const newList = [...initialData];
    newList[index].isChecked = v;

    setInitialData(newList);
  }

  function handleOnChangeSelectAll(v) {
    return v ? selectAll() : deselectAll();
  }

  function isAllSelected() {
    const containsCheckedFalse = _.some(initialData, ['isChecked', false]);

    return !containsCheckedFalse;
  }

  function createFilteredList() {
    const searchList = initialData;
    const fuse = new Fuse(searchList, FUSE_OPTIONS);
    const results = fuse.search(searchTerm);
    const filteredWithCheckState = results.map(result => _.find(initialData, ['id', result.id]));

    return searchTerm === '' ? initialData : filteredWithCheckState;
  }

  function handleSearch(searchTerm) {
    setSearchTerm(searchTerm);
  }

  const filteredList = orderByKey(createFilteredList(), 'name', 'asc');

  return (
    <>
      {!expanded &&
        <div ref={element} id={label}>
          <div className={`filter-chip ${isRemovable() ? 'removable' : ''}`} onClick={(e) => handleSetExpand(true, e)}>
            <div className="filter-chip-header">
              <span className="chip-label">
                {`${label} (${countChecked()})`}
              </span>
              <>
                {isRemovable() &&
                  <button id={`${idFormatter(label)}-filter-cancel`} className="chip-remove-button" onClick={(e) => handleRemove(e)}>
                    <FontIcon>cancel</FontIcon>
                  </button>
                }
                {!expanded && !isRemovable() &&
                  <span id={`${idFormatter(label)}-filter-drop-down`} className="chip-icon">
                    <FontIcon>arrow_drop_down</FontIcon>
                  </span>
                }
              </>
            </div>
          </div>
        </div>
      }
      {expanded &&
        <div ref={element} id={label}>
          <div className={`filter-chip expanded expand-${direction}`}>
            <div className="filter-chip-header">
              <span className="chip-label">
                {label}
              </span>
              <button id={`${idFormatter(label)}-filter-close-chip`} className="chip-close-icon" onClick={(e) => handleSetExpand(false, e)}>
                <FontIcon>close</FontIcon>
              </button>
            </div>
            <div className="filter-chip-dropdown">
              <h5 className="chip-section-header">Search</h5>
              <TextField
                id={`${idFormatter(label)}-search-input`}
                placeholder="What are you looking for...?"
                rightIcon={<FontIcon>search</FontIcon>}
                fullWidth
                onChange={(v) => handleSearch(v)}
              />
              <br />
              {!_.isEmpty(filteredList) &&
                <Checkbox
                  id={`${idFormatter(label)}-top-control-select`}
                  className="ph-checkbox"
                  label="Select all"
                  name="select-all"
                  labelBefore
                  onChange={(v) => handleOnChangeSelectAll(v)}
                  checked={isAllSelected()}
                />
              }
              <div className="list-container">
                <List className="chip-list">
                  {filteredList && filteredList.map((item, index) => (
                    <ListItemControl
                      key={index}
                      secondaryAction={(
                        <Checkbox
                          id={`${idFormatter(label)}-check-input-${index}`}
                          className="ph-checkbox"
                          name={`${idFormatter(label)}-check-input-${index}`}
                          label={truncate(item[labelKey], 42)}
                          title={item[labelKey]}
                          onChange={(v) => handleOnChangeCheckbox(v, item)}
                          checked={item.isChecked}
                          labelBefore
                        />
                      )}
                    />
                  ))}
                </List>
              </div>
              <br />
              <div className="row end-xs">
                <div className="col-xs">
                  <Button
                    disabled={filteredList.length === 0}
                    id={`${idFormatter(label)}-filter-apply`}
                    flat
                    primary
                    swapTheming
                    onClick={(e) => handleApply(false, e)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

FilterChip.propTypes = {
  direction: PropTypes.string, // open direction
  filterKey: PropTypes.string, // name of parent filter state property
  label: PropTypes.string.isRequired, //  Title displayed in filter
  labelKey: PropTypes.string, // property key to display the item name, sends (value, filterKey), defaults to 'name'
  onApply: PropTypes.func, // method to pass values to parent sends (value, filterKey)
  onRemove: PropTypes.func //optional function executed after onApply if remove button is clicked
};

FilterChip.defaultProps = {
  direction: 'left',
  labelKey: 'name'
};

export default FilterChip;
