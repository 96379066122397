import './NoMatch.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

const NoMatch = () => {
  return (
    <main id="no-match">
      <div className="wrapper">
        <div className="row center-xs">
          <div className="col-xs-12">
            <h1>404</h1>
          </div>
          <div className="col-xs-12">
            <p>You made a wrong turn.</p>
            <Link to={routes.ROOT}>Home</Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NoMatch;
