import axios from 'axios';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import moment from 'moment';
import { Cookies } from 'react-cookie';
import { FUSE_OPTIONS } from './fuseOptions';

const cookies = new Cookies();

const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

export { FUSE_OPTIONS };

export const idFormatter = (string) => string.toLowerCase().split(' ').join('-');

export function createFilterList(data, keyName) {
  if (keyName) {
    return _.uniqBy(data.map((item) => ({ name: item[keyName], id: item.id, isChecked: true })), 'name');
  } else {
    return _.uniqBy(data.map((item) => ({ name: item, id: item, isChecked: true })), 'name');
  }
}

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

// convert date to more readable format
export function makeTimeReadable(date) {
  return moment.utc(date).format('MM/DD/YY | h:mm a');
}

export function getMonthAndYear(date) {
  return moment.utc(date).format('MMMM YYYY');
}

export function getMonthAndYearForStartOfWeek(date) {
  return moment(date).startOf('week').format('MMMM YYYY');
}

export function getCurrentMonth() {
  return moment().startOf('month').toISOString();
}

export function getNextMonth(date) {
  return moment(date).startOf('month').add(1, 'months').toISOString();
}

export function getPrevMonth(date) {
  return moment(date).startOf('month').subtract(1, 'months').toISOString();
}

export function getWeek(date) {
  return moment(date).startOf('week').toISOString();
}

export function getNextWeek(date) {
  return moment(date).startOf('week').add(1, 'weeks').toISOString();
}

export function getPrevWeek(date) {
  return moment(date).startOf('week').subtract(1, 'weeks').toISOString();
}

// sort by key
export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

// truncate string with max character count
export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

// get cookie by name
export function getCookie(name) {
  return cookies.get(name);
}

// update auth cookie
export function refreshSession() {
  const value = getCookie('86553b39');

  if (value && value !== 'undefined') {
    return ajax.post('authenticate?refreshCookie=true');

  } else {
    console.error(`Can not refresh session because current cookie expired`);
  }
}

// Sign out user and redirect to suite login
export function signOut(params = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + params}`);
}

function authorizationCheck(authCookie) {
  return getAuthUser(authCookie).g.filter(rel => rel.r === 'GLOBAL_ADMIN').length > 0;
}

function checkForEnvMatch() {
  return getAuthUser().ne === process.env.REACT_APP_ENV_TAG;
}

export const checkAuth = () => {
  const authCookie = getCookie('86553b39');

  if (authCookie && checkForEnvMatch() && authorizationCheck(authCookie)) {
    return true;

  } else {
    return false;
  }
};

export function getAuthUser() {
  return jwt.decode(getCookie('86553b39'), { complete: true }).payload.payload;
}

export function getSessionTime() {
  const iat = jwt.decode(getCookie('86553b39'), { complete: true }).payload.iat;
  return Math.abs((new Date(iat * 1000) - new Date()) / 1000 / 60);
}
