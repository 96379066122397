import './SideMenu.scss';
import React, { useState } from 'react';
import { List, ListItem, FontIcon, ResizeObserver } from 'react-md';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

const SideMenu = ({ activeRoute }) => {
  const [collapseMenu, setCollapseMenu] = useState(false);

  function handleResize({ width }) {
    setCollapseMenu(width <= 1220);
  };

  return (
    <>
      <aside className={`sidebar ${collapseMenu ? 'collapsed' : ''}`}>
        {collapseMenu ?
          <div className="side-menu-small">
            <List>
              <ListItem
                leftIcon={<FontIcon key="event">event</FontIcon>}
                primaryText="" className={activeRoute === `${routes.WEEK}` ? 'active' : ''}
                component={Link}
                to={`${routes.WEEK}`}
              />
              <ListItem
                leftIcon={<FontIcon key="schedule">list</FontIcon>}
                primaryText="" className={activeRoute === `${routes.AGENDA}` ? 'active' : ''}
                component={Link}
                to={`${routes.AGENDA}`}
              />
            </List>
          </div>
          :
          <div className="side-menu">
            <List>
              <ListItem
                leftIcon={<FontIcon key="event">event</FontIcon>}
                primaryText="Week View"
                className={activeRoute === `${routes.WEEK}` ? 'active' : ''}
                component={Link} to={`${routes.WEEK}`}
              />
              <ListItem
                leftIcon={<FontIcon key="schedule">list</FontIcon>}
                primaryText="Agenda View"
                className={activeRoute === `${routes.AGENDA}` ? 'active' : ''}
                component={Link} to={`${routes.AGENDA}`}
              />
            </List>
          </div>
        }
        <ResizeObserver watchWidth target="root" onResize={handleResize} />
      </aside>
    </>
  );
};

export default SideMenu;
