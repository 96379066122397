import './EventTile.scss';
import React, { useState } from 'react';
import { makeTimeReadable } from '../../utils';
import moment from 'moment';
import { DialogContainer, Button, FontIcon } from 'react-md';
import americanFootballImg from './americanfootball.png';
import otherImg from './other.png';
import concertImg from './concert.png';
import { CircleCheckIcon, HelpOutlineIcon, WarningCircleIcon } from '../../assets/react-icons';

function getStatusIndicatorStyle(status) {
  const statusColor = {
    PREPAIDS_ASSOCIATED: '#02E079',
    EXPECTED_NOT_RECEIVED: '#FF4E71',
    RECEIVED_NOT_ASSOCIATED: '#F7CA18',
    NO_PREPAIDS_EXPECTED: '#BEBEBE',
    UNKNOWN: '#BEBEBE'
  };

  return statusColor[status];
}

function getBackgroundColor(status, date) {
  const statusColor = {
    PREPAIDS_ASSOCIATED: '#D9FBEB',
    EXPECTED_NOT_RECEIVED: '#FFE5EA',
    RECEIVED_NOT_ASSOCIATED: '#FEF7DD',
    NO_PREPAIDS_EXPECTED: '#EEEEEE',
    UNKNOWN: '#EEEEEE'
  };

  return moment(date).isSameOrAfter(moment()) ? statusColor[status] : '#F4F6F8';
}

function getIconForStatus(status) {
  const statuses = {
    'RECEIVED_NOT_ASSOCIATED': <WarningCircleIcon color="rgba(0,0,0, .6)" width={24} height={24} />,
    'PREPAIDS_ASSOCIATED': <CircleCheckIcon color="rgba(0,0,0, .6)" width={24} height={24} />,
    'EXPECTED_NOT_RECEIVED': <HelpOutlineIcon color="rgba(0,0,0, .6)" width={24} height={24} />,
  };

  return statuses[status];
}

function getImage(tag) {
  if (tag !== '') {
    var images = {
      'Sporting Event': () => americanFootballImg,
      'Other Event': () => otherImg,
      'Musical Event': () => concertImg
    };
    return (images[tag] || otherImg)();
  } else {
    return otherImg;
  }
}

const EventTile = ({ event }) => {
  const [visible, setVisible] = useState(false);

  function hide() {
    setVisible(false);
  }

  function show() {
    setVisible(true);
  }

  function formatStatus(status) {
    return status.replace(/[_]/g, ' ');
  }

  return (
    <>
      <div onClick={show} className="event-tile" style={{ backgroundColor: getBackgroundColor(event.status, event.to) }}>
        <div className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(event.status) }} />
        <div className="event-tile-name">{event.name}</div>
        <div className="event-tile-text">{event.landmarkName}</div>
        <div className="event-tile-text">{event.regionName}</div>
        <div className="event-tile-text">{makeTimeReadable(event.parkingFrom)}</div>
        <div className="event-tile-text">{formatStatus(event.status)}</div>
      </div>
      <DialogContainer
        id="event-details-dialog"
        className="event-details-dialog"
        visible={visible}
        onHide={hide}
        aria-describedby="event-details"
        portal
        lastChild
        focusOnMount={false}
        paddedContent={false}
        width={532}
      >
        <div className="event-details-dialog-content">
          <Button
            icon
            className="close-button"
            onClick={hide}
          >
            close
          </Button>
          <div className="event-image-container">
            <img src={getImage(event.type)} alt="event" />
          </div>
          <div className="event-details">
            <ul className="details-list">
              <li>
                <span className="details-icon">
                  <FontIcon>turned_in</FontIcon>
                </span>
                <div className="event-name">
                  {event.name}
                </div>
              </li>
              <li>
                <span className="details-icon">
                  <FontIcon>room</FontIcon>
                </span>
                <div className="detail-name">{event.landmarkName}</div>
                <div className="detail-description">Location</div>
              </li>
              <li>
                <span className="details-icon">
                  <FontIcon>public</FontIcon>
                </span>
                <div className="detail-name">{event.regionName}</div>
                <div className="detail-description">Region</div>
              </li>
              <li>
                <span className="details-icon">
                  <FontIcon>event</FontIcon>
                </span>
                <div className="detail-name">{makeTimeReadable(event.from)}</div>
                <div className="detail-description">Event time</div>
              </li>
              <li>
                <div className="detail-name">{makeTimeReadable(event.parkingFrom)}</div>
                <div className="detail-description">Parking time</div>
              </li>
              <li>
                <span className="details-icon">
                  <span className="status-icon" style={{ backgroundColor: getStatusIndicatorStyle(event.status) }} />
                </span>
                <div className="detail-name">{formatStatus(event.status)}</div>
                <div className="detail-description">Status</div>
                <ul className="details-list integrations-list">
                  {event.integrations && event.integrations.length > 0 && event.integrations.map((integration, index) => (
                    <li key={`${event.id}-${index}-${event.from}-${Math.random()}`}>
                      <span className="details-icon">
                        {getIconForStatus(integration.status)}
                      </span>
                      <div className="detail-name">
                        <span className="integration-name">{integration.sourceName}</span> - {formatStatus(integration.status)}
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
              {event.type &&
                <li>
                  <span className="details-icon">
                    <FontIcon>label</FontIcon>
                  </span>
                  <div className="detail-name">{event.type}</div>
                  <div className="detail-description">Event type</div>
                </li>
              }
              <li>
                <span className="details-icon">
                  <FontIcon>turned_in</FontIcon>
                </span>
                <div className="detail-name">Pricing Template</div>
                <span className="detail-tag">{event.tagName}</span>
              </li>
            </ul>
          </div>
        </div>
      </DialogContainer>
    </>
  );
};

export default EventTile;
