import './TopActionBar.scss';
import React from 'react';
import { FilterBar, FilterChip, SearchInput } from '../';
import { createFilterList, isNotEmpty } from '../../utils';

const TopActionBar = ({ update, dateControls, filterOptions }) => {

  return (
    <div className="top-action-bar">
      <div className="row">
        <div className="col-xs-12 col-md" style={{ maxWidth: '400px' }}>
          <div className="date-controls">
            {dateControls}
          </div>
        </div>
        <div className="col-xs-12 col-md">
          <div className="action-container">
            <SearchInput
              update={update}
            />
            <FilterBar>
              {isNotEmpty(filterOptions.location) &&
                <FilterChip
                  data={createFilterList(filterOptions.location)}
                  onApply={update}
                  filterKey="locations"
                  label="Landmark"
                />
              }
              {isNotEmpty(filterOptions.location) &&
                <FilterChip
                  data={createFilterList(filterOptions.region)}
                  onApply={update}
                  filterKey="regions"
                  label="Region"
                />
              }
              {isNotEmpty(filterOptions.status) &&
                <FilterChip
                  data={createFilterList(filterOptions.status)}
                  onApply={update}
                  filterKey="statuses"
                  label="Status"
                />
              }
            </FilterBar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopActionBar;
