import './Agenda.scss';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { TopActionBar, EventTile } from '../../components/';
import { getMonthAndYear, getCurrentMonth, getPrevMonth, getNextMonth } from '../../utils';
import { Button } from 'react-md';
import animateScrollTo from 'animated-scroll-to';

const scheduleQuery = loader('../../graphql/schedule.query.gql');

const Agenda = () => {
  const [scrollY, setScrollY] = useState(0);
  const [variables, setVariables] = useState({
    'interval': 'MONTH',
    'periods': 1,
    'startDate': getCurrentMonth(),
    'statuses': null,
    'locations': null,
    'regions': null,
    'tagNames': null,
    'eventTypes': null,
    'eventName': null,
    'keyword': null
  });

  const { loading, error, data, refetch } = useQuery(scheduleQuery, { variables });

  function updateVariables(value, key) {
    const newVariables = variables;
    newVariables[key] = value;

    setVariables(newVariables);
    refetch(newVariables);
  }

  function topFunction() {
    animateScrollTo(0);
  }

  function setScroll() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', setScroll);
    }

    watchScroll();

    return () => {
      window.removeEventListener('scroll', setScroll);
    };
  });

  return (
    <>
      <div id="agenda">
        <div className="wrapper-full">
          <section className="row">
            <div className="col-xs-12">
              {!!data &&
                <TopActionBar
                  update={updateVariables}
                  variables={variables}
                  filterOptions={data.schedule.filterOpts}
                  dateControls={
                    <>
                      <Button
                        className="button ghost"
                        flat
                        primary
                        id="today-button"
                        onClick={() => updateVariables(getCurrentMonth(), 'startDate')}
                      >
                        Today
                      </Button>
                      <Button
                        className="date-nav"
                        icon
                        primary
                        onClick={() => updateVariables(getPrevMonth(variables.startDate), 'startDate')}
                      >
                        chevron_left
                      </Button>
                      <Button
                        className="date-nav"
                        icon
                        primary
                        onClick={() => updateVariables(getNextMonth(variables.startDate), 'startDate')}
                      >
                        chevron_right
                      </Button>
                      <h4>{getMonthAndYear(variables.startDate)}</h4>
                    </>
                  }
                />
              }
            </div>
          </section>
          <hr className="separator" />
          <br />
          {!loading && !error && data.schedule.buckets.map((bucket, index) => (
            <section key={index} className="row">
              <div className="col-xs-12">
                {bucket.events.map((event, index) => (
                  <div key={`${event.id}-${index}`}>
                    <EventTile event={event} />
                  </div>
                ))}
              </div>
            </section>
          ))}
          {!loading && !error && (scrollY > 200) &&
            <Button
              className="to-top-button"
              icon
              secondary
              swapTheming
              onClick={() => topFunction()}
            >
              keyboard_arrow_up
            </Button>
          }
        </div>
      </div>
      <LoadingSpinner show={loading} delay={400} />
    </>
  );
};

export default Agenda;
