import { gql, useQuery } from '@apollo/client';
import { SessionDialog } from '@parkhub/parkhub-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import Layout from './containers/Layout/Layout';
import { checkAuth, getSessionTime, refreshSession, signOut } from './utils';

const GET_AUTH_USER = gql`
  {
    authUser @client
  }
`;

const App = (props) => {
  const checkTime = useRef(false);
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const { data, loading } = useQuery(GET_AUTH_USER);
  const location = useLocation();

  function hide() {
    setSessionModalVisible(false);
  }

  function show() {
    setSessionModalVisible(true);
  }

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);
          const checkSessionTimeout = () => {

            if (!checkAuth()) {
              signOut();
            } else {
              const minutes = getSessionTime();
              if (minutes > 1440) {
                setSessionModalVisible(false);
                signOut('?expired=true');

              } else if (minutes > 1438 && minutes < 1440) {
                show();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        });
    }, [],
  );

  const checkLoggedIn = useCallback(
    () => {
      if (!checkAuth()) {
        window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
      } else {
        startTimeout();
      }
    }, [startTimeout],
  );

  useEffect(() => {
    checkLoggedIn();

    return () => {
      clearInterval(checkTime.current);
    };
  }, [location, checkLoggedIn]);

  return (
    <>
      {!loading &&
        <>
          <Layout authUser={data.authUser} {...props} />
          <SessionDialog
            onHide={hide}
            visible={sessionModalVisible}
            onContinue={startTimeout}
            onTimeExpire={() => signOut('?expired=true')}
            onSignOut={signOut}
          />
        </>
      }
    </>
  );
};

export default withRouter(App);
