import './Week.scss';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Button } from 'react-md';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { TopActionBar, EventTile } from '../../components/';
import { getWeek, getPrevWeek, getNextWeek, getMonthAndYearForStartOfWeek } from '../../utils';
import moment from 'moment';

const scheduleQuery = loader('../../graphql/schedule.query.gql');

const Week = () => {
  const [variables, setVariables] = useState({
    'interval': 'WEEK',
    'periods': 1,
    'startDate': getWeek(),
    'statuses': null,
    'locations': null,
    'regions': null,
    'tagNames': null,
    'eventTypes': null,
    'eventName': null,
    'keyword': null
  });

  const { loading, error, data, refetch } = useQuery(scheduleQuery, { variables });

  function updateVariables(value, key) {
    const newVariables = variables;
    newVariables[key] = value;

    setVariables(newVariables);
    refetch(newVariables);
  }

  function isToday(date) {
    return moment(date).isSame(new Date(), 'day');
  }

  function isBeforeToday(date) {
    return moment(date).isBefore(moment(), 'day');
  }

  function isAfterToday(date) {
    return moment(date).isAfter(moment(), 'day');
  }

  function getDateStyle(date) {
    if (isToday(date)) {
      return 'date-today';
    } else if (isBeforeToday(date)) {
      return 'date-before';
    } else if (isAfterToday(date)) {
      return 'date-after';
    }
  }

  function createWeek(date) {
    const week = [];
    const current = getWeek(date);

    for (let step = 0; step < 7; step++) {
      week.push({
        weekday: moment(current).add(step, 'days').format('ddd'),
        calenderDay: moment(current).add(step, 'days').format('D'),
        date: moment(current).add(step, 'days').toISOString(),
        events: data.schedule.buckets[step].events
      });
    }

    return week;
  }

  return (
    <>
      <div id="week">
        <div className="wrapper-full">
          <section className="row">
            <div className="col-xs-12">
              {!!data &&
                <TopActionBar
                  update={updateVariables}
                  variables={variables}
                  filterOptions={data.schedule.filterOpts}
                  dateControls={
                    <>
                      <Button
                        className="button ghost"
                        flat
                        primary
                        id="today-button"
                        onClick={() => updateVariables(getWeek(), 'startDate')}
                      >
                        Today
                      </Button>
                      <Button
                        className="date-nav"
                        icon
                        primary
                        onClick={() => updateVariables(getPrevWeek(variables.startDate), 'startDate')}
                      >
                        chevron_left
                      </Button>
                      <Button
                        className="date-nav"
                        icon
                        primary
                        onClick={() => updateVariables(getNextWeek(variables.startDate), 'startDate')}
                      >
                        chevron_right
                      </Button>
                      <h4>{getMonthAndYearForStartOfWeek(variables.startDate)}</h4>
                    </>
                  }
                />
              }
            </div>
          </section>
          <hr className="separator" />
          <section className="row calender">
            {!loading && !error && createWeek(variables.startDate).map((day, index) => (
              <div key={index} className="col-xs day-container">
                <div className={`calender-head ${getDateStyle(day.date)}`}>
                  <h6 className="day">{day.weekday}</h6>
                  <h4 className="date">{day.calenderDay}</h4>
                </div>
                <div className="calender-event-container">
                  {day.events.map((event, index) => (
                    <div key={`${event.id}-${index}`}>
                      <EventTile event={event} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
      <LoadingSpinner show={loading} delay={400} />
    </>
  );
};

export default Week;
