import ParkhubLogo from '../../assets/parkhub-scheduler-logo-gray.svg';
import './Layout.scss';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SideMenu } from '../../components';
import Agenda from '../Agenda/Agenda';
import Week from '../Week/Week';
import NoMatch from '../NoMatch/NoMatch';
import * as routes from '../../constants/routes';
import { Header } from '@parkhub/parkhub-ui';
import { signOut } from '../../utils';

const Layout = (props) => {
  useEffect(() => {
    if (props.location.pathname === routes.ROOT) {
      props.history.replace(routes.WEEK);
    }
  });

  return (
    <>
      <Header
        authUser={props.authUser}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={213}
        appMenuActive
        logoClick={() => props.history.push(routes.ROOT)}
      />
      <main id="layout">
        <SideMenu activeRoute={props.location.pathname} />
        <div className="content-container">
          <Switch>
            <Route exact path={routes.WEEK} render={props => <Week {...props} />} />
            <Route exact path={routes.AGENDA} render={props => <Agenda {...props} />} />
            <Route component={NoMatch} />
          </Switch>
        </div>
      </main>
    </>
  );
};

export default Layout;
